import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { Grid } from '@material-ui/core'
import PhotoSection from './Components/Photos'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { gql, useQuery, useMutation } from '@apollo/client'
import { Hero } from './Components/Hero'
import { Services } from './Components/Services'
import { Reviews } from './Components/Reviews'
import { ThemeProvider, Typography, CssBaseline, Button, TextField, Checkbox, List, ListItem } from '@material-ui/core/'
import { milanoTheme } from './milano.theme'
import LazyLoad from 'react-lazyload'
import Skeleton from '@material-ui/lab/Skeleton'
import axios from 'axios'
import { submitUmamiEvent } from './tools'

const TEXT = gql`
  query Document($id: String!) {
    document(id: $id) {
      data
    }
  }
`

const OPPORTUNITY_REQUEST = gql`
  mutation OpportunityRequest($name: String!, $email: String!, $phone: String!, $description: String) {
    opportunityRequest(name: $name, email: $email, phone: $phone, description: $description) {
      name
      email
      phone
      description
    }
  }
`

function PageContent({ realCreds }) {
  const [firstPass, setFirstPass] = useState(false)
  const [creds, setCreds] = useState({ username: '', password: '' })
  const [reviewData, setReviewData] = useState()

  const handleChange = e => {
    const { name, value } = e.target
    setCreds(creds => ({ ...creds, [name]: value }))
  }

  const handleLogin = () => {
    if ('jordan' === creds.username && 'jordanthebarber' === creds.password) {
      localStorage.setItem('credentials', creds)
      window.location = '/edit'
    } else {
      alert('no')
    }
  }

  useScrollPosition(({ prevPos, currPos }) => {
    if (document.getElementById('topbar')) {
      if (document.getElementById('topbar').classList.contains('open')) {
        document.getElementById('topbar').classList.toggle('open')
        document.getElementById('topbar').classList.toggle('closed')
      }
    }
    if (document.getElementById('review-slider-container')) {
      if (currPos.y < (document.getElementById('review-slider-container').offsetTop - 400) * -1) {
        if (firstPass) {
        } else {
          document.getElementById('review-slider-container').scrollTo(500, 0)
          setTimeout(() => setFirstPass(true), 1000)
        }
      }
    }
  })

  const LoginDialog = () => {
    return (
      <div id='login-dialog' className='dialog'>
        <button
          onClick={() => (document.getElementById('login-dialog').style.display = 'none')}
          style={{
            background: '#B92C3C',
            display: 'flex',
            top: '5px',
            right: '25px',
            position: 'fixed',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            borderRadius: '50px',
            width: '40px',
            height: '40px',
            padding: '12px',
          }}
        >
          {' '}
          X{' '}
        </button>
        <div id='login-dialog-content' className='content'>
          <input
            key='ho'
            name='username'
            value={creds.username}
            onChange={handleChange}
            placeholder='username'
            type='text'
          />
          <input
            name='password'
            value={creds.password}
            onChange={handleChange}
            placeholder='password'
            type='password'
          />
          <button onClick={handleLogin}> Login </button>
        </div>
      </div>
    )
  }

  const getBrowserData = () => {
    try {
      return {
        browser_data: {
          oscpu: window.navigator.oscpu || 'not supported',
          screensize: `${window.screen.height}x${window.screen.width}`,
          viewportSize: `${window.innerHeight}x${window.innerWidth}`,
          userAgent: window.navigator.userAgent,
          userAgentBrands:
            (window.navigator.userAgentData &&
              window.navigator.userAgentData &&
              window.navigator.userAgentData.brands.map(brand => brand.brand).join(',')) ||
            'not supported',
          platform: window.navigator.platform || 'not supported',
          languages: (window.navigator.languages && window.navigator.languages.join(',')) || 'not supported',
          variant: localStorage.getItem('CBL_EXPERIMENT_VAR') || 'not available',
        },
      }
    } catch {
      return null
    }
  }

  const BugDialog = () => {
    const [info, setInfo] = useState(false)
    const [booking, setBooking] = useState(false)
    const [link, setLink] = useState(false)
    const [desc, setDesc] = useState()
    const [success, setSuccess] = useState(false)

    const handleChange = evt => {
      const name = evt.target.name
      if (name === 'info') {
        setInfo(!info)
      } else if (name === 'booking') {
        setBooking(!booking)
      } else if (name === 'link') {
        setLink(!link)
      } else if (name === 'desc') {
        setDesc(evt.target.value)
      }
    }

    const submitData = () => {
      // reportBug({variables: {boxes: `${info? 'info bug, ':''} ${booking? 'booking bug, ':''} ${link? 'link bug':''}`, description: desc }}).then((res) => {

      // })

      axios({
        method: 'post',
        url: 'https://api.doomo.dev/create_document',
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiZGJhZWZlM2ItNDhkNS00YmUyLWJiNzgtYTY3Zjg2M2EwNjM2IiwibG9naW5fYXR0ZW1wdF9pZCI6ImRiYWVmZTNiLTQ4ZDUtNGJlMi1iYjc4LWE2N2Y4NjNhMDYzNiJ9.sont2LTPIpwEp_ujmSfXpOAp1SbNJxiF7elTCfWhBjo`,
        },
        data: {
          collection_id: '024b8650-0332-4231-b1c7-e511615c1406',
          user_id: 'f987006a-ffad-4e09-9385-507728629c19',
          name: 'Bug report',
          data: {
            bug_type: `${info ? 'info bug, ' : ''} ${booking ? 'booking bug, ' : ''} ${link ? 'link bug' : ''}`,
            description: desc,
            browser_data: getBrowserData(),
          },
        },
      }).then(() => {
        setSuccess(true)
        setTimeout(() => {
          document.getElementById('bug-dialog').style.display = 'none'
        }, 1500)
      })
    }

    const isDisabled = (info || booking || link) && desc

    return (
      <div id='bug-dialog' className='dialog'>
        <button
          onClick={() => (document.getElementById('bug-dialog').style.display = 'none')}
          style={{
            background: '#B92C3C',
            display: 'flex',
            top: '5px',
            right: '25px',
            position: 'fixed',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            borderRadius: '50px',
            width: '40px',
            height: '40px',
            padding: '12px',
          }}
        >
          {' '}
          X{' '}
        </button>
        {success && (
          <Grid
            className='content'
            style={{
              padding: '2rem 2rem',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h2> Thanks for your feedback.</h2>
          </Grid>
        )}

        {!success && (
          <Grid
            className='content'
            style={{
              padding: '2rem 2rem',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid item xs={12}>
              <h3
                style={{
                  fontSize: '12px',
                  color: '#aaa',
                  margin: '0px auto',
                  textAlign: 'center',
                }}
              >
                REPORT A BUG
              </h3>
            </Grid>
            <Grid item xs={12}>
              <h3
                style={{
                  fontSize: '20px',
                  margin: '0px auto 2rem auto',
                  textAlign: 'center',
                }}
              >
                What seems to be the problem?
              </h3>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginRight: 'auto',
              }}
            >
              <Grid container style={{ alignItems: 'center' }}>
                <Checkbox onChange={handleChange} name='booking' checked={booking} />
                <Typography component='p' onClick={() => setBooking(true)}>
                  {' '}
                  Problem booking an appointment{' '}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginRight: 'auto',
              }}
            >
              <Grid container style={{ alignItems: 'center' }}>
                <Checkbox onChange={handleChange} name='link' checked={link} />
                <Typography component='p' onClick={() => setLink(true)}>
                  {' '}
                  Broken link on the website
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginRight: 'auto',
              }}
            >
              <Grid container style={{ alignItems: 'center' }}>
                <Checkbox onChange={handleChange} name='info' checked={info} />
                <Typography component='p' onClick={() => setInfo(true)}>
                  {' '}
                  Website information is wrong{' '}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginRight: 'auto',
                marginTop: '1.5rem',
                width: '100%',
              }}
            >
              <Grid container style={{ alignItems: 'center' }}>
                <Typography component='p' style={{ fontSize: '14px', marginLeft: 0 }}>
                  {' '}
                  Describe your issue below{' '}
                </Typography>
                <TextField
                  variant='outlined'
                  name='desc'
                  value={desc}
                  onChange={handleChange}
                  style={{ width: '100%', marginTop: '.25rem' }}
                  type='text'
                  multiline
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '1.5rem',
              }}
            >
              <Grid container>
                <Button
                  onClick={submitData}
                  disabled={!isDisabled}
                  size='small'
                  style={{ margin: '0px auto', fontSize: '14px' }}
                >
                  Report
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }

  const RequestDialog = () => {
    const [name, setName] = useState()
    const [business, setBusiness] = useState()
    const [email, setEmail] = useState()
    const [desc, setDesc] = useState()
    const [success, setSuccess] = useState(false)

    const handleChange = evt => {
      const name = evt.target.name
      if (name === 'name') {
        setName(evt.target.value)
      } else if (name === 'business') {
        setBusiness(evt.target.value)
      } else if (name === 'email') {
        setEmail(evt.target.value)
      } else if (name === 'desc') {
        setDesc(evt.target.value)
      }
    }

    const submitData = () => {
      // createRequest({variables: {name: name, email: email, business: business, description: desc }}).then((res) => {
      //   console.log(res, desc)
      // })

      axios({
        method: 'post',
        url: 'https://api.doomo.dev/create_document',
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiZGJhZWZlM2ItNDhkNS00YmUyLWJiNzgtYTY3Zjg2M2EwNjM2IiwibG9naW5fYXR0ZW1wdF9pZCI6ImRiYWVmZTNiLTQ4ZDUtNGJlMi1iYjc4LWE2N2Y4NjNhMDYzNiJ9.sont2LTPIpwEp_ujmSfXpOAp1SbNJxiF7elTCfWhBjo`,
        },
        data: {
          collection_id: '39898bd2-be5b-407c-9343-239c8d6671a3',
          user_id: 'f987006a-ffad-4e09-9385-507728629c19',
          name: 'Web dev work inquiry',
          data: {
            name,
            email,
            business,
            desc,
            browserData: getBrowserData(),
          },
        },
      }).then(() => {
        setSuccess(true)
        setTimeout(() => {
          document.getElementById('request-dialog').style.display = 'none'
        }, 1500)
      })
    }

    const isDisabed = name && name.length > 0 && email && email.length > 0 && business && business.length > 0

    return (
      <div id='request-dialog' className='dialog'>
        <button
          onClick={() => (document.getElementById('request-dialog').style.display = 'none')}
          style={{
            background: '#B92C3C',
            display: 'flex',
            top: '5px',
            right: '25px',
            position: 'fixed',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            borderRadius: '50px',
            width: '40px',
            height: '40px',
            padding: '12px',
          }}
        >
          {' '}
          X{' '}
        </button>

        {success && (
          <Grid
            className='content'
            style={{
              padding: '2rem 2rem',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h2> Thanks for your feedback!</h2>
            <p> I will be in touch with you shortly. </p>
          </Grid>
        )}
        {!success && (
          <Grid
            className='content'
            style={{
              padding: '2rem 2rem',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid item xs={12}>
              <h3
                style={{
                  fontSize: '12px',
                  color: '#aaa',
                  margin: '0px auto',
                  textAlign: 'center',
                }}
              >
                LEARN MORE
              </h3>
            </Grid>
            <Grid item xs={12}>
              <h3
                style={{
                  fontSize: '20px',
                  margin: '0px auto 2rem auto',
                  textAlign: 'center',
                }}
              >
                Interesed in a Custom Website?
              </h3>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginRight: 'auto',
              }}
            >
              <Grid container style={{ alignItems: 'center' }}>
                <p
                  style={{
                    fontSize: '16px',
                    margin: '0px auto 2rem auto',
                    textAlign: 'center',
                  }}
                >
                  This website was specially made for Jordan at the Milano Barbershop. No wordpress or squarespace, no
                  website builders or any subscription fees to pay for.
                  <br />
                  <br />
                  Just a guy who wants to support Jordan and help his business grow. If you like what you the see on
                  this website and are interested in seeing if I can work for you, fill out the form and I’ll get back
                  to you. I can code, set up, design and maintain a wide variety of internet features to help out your
                  business. Both web apps and native desktop, iPhone and Android apps.
                  <br />
                  <br />
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginRight: 'auto',
                marginTop: '.5rem',
                width: '100%',
              }}
            >
              <Grid container style={{ alignItems: 'center' }}>
                <Typography component='p' style={{ fontSize: '14px', marginLeft: 0 }}>
                  {' '}
                  Your name{' '}
                </Typography>
                <TextField
                  name='name'
                  variant='outlined'
                  size='small'
                  value={name}
                  onChange={handleChange}
                  style={{ width: '100%', marginTop: '.25rem' }}
                  type='text'
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginRight: 'auto',
                marginTop: '.5rem',
                width: '100%',
              }}
            >
              <Grid container style={{ alignItems: 'center' }}>
                <Typography component='p' style={{ fontSize: '14px', marginLeft: 0 }}>
                  {' '}
                  Your business name{' '}
                </Typography>
                <TextField
                  name='business'
                  variant='outlined'
                  size='small'
                  value={business}
                  onChange={handleChange}
                  style={{ width: '100%', marginTop: '.25rem' }}
                  type='text'
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginRight: 'auto',
                marginTop: '.5rem',
                width: '100%',
              }}
            >
              <Grid container style={{ alignItems: 'center' }}>
                <Typography component='p' style={{ fontSize: '14px', marginLeft: 0 }}>
                  {' '}
                  Your email{' '}
                </Typography>
                <TextField
                  name='email'
                  variant='outlined'
                  size='small'
                  value={email}
                  onChange={handleChange}
                  style={{ width: '100%', marginTop: '.25rem' }}
                  type='text'
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginRight: 'auto',
                marginTop: '.5rem',
                width: '100%',
              }}
            >
              <Grid container style={{ alignItems: 'center' }}>
                <Typography component='p' style={{ fontSize: '14px', marginLeft: 0 }}>
                  {' '}
                  A brief description of your idea (optional){' '}
                </Typography>
                <TextField
                  name='desc'
                  variant='outlined'
                  value={desc}
                  onChange={handleChange}
                  style={{ width: '100%', marginTop: '.25rem' }}
                  type='text'
                  multiline
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '1.5rem',
              }}
            >
              <Grid container>
                <Button
                  onClick={submitData}
                  disabled={!isDisabed}
                  size='small'
                  style={{ margin: '0px auto', fontSize: '14px' }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }

  // const OpportunityDialog = () => {
  //   const [name, setName] = useState()
  //   const [phone, setPhone] = useState()
  //   const [email, setEmail] = useState()
  //   const [desc, setDesc] = useState()
  //   const [opportunityRequest] = useMutation(OPPORTUNITY_REQUEST)
  //   const [success, setSuccess] = useState(false)

  //   const handleChange = (evt) => {
  //     const name = evt.target.name
  //     if(name === 'name') {
  //       setName(evt.target.value)
  //     }
  //     else if (name === 'phone') {
  //       setPhone(evt.target.value)
  //     }
  //     else if (name === 'email') {
  //       setEmail(evt.target.value)
  //     }
  //     else if (name === 'desc') {
  //       setDesc(evt.target.value)
  //     }
  //   }

  //   const submitData = () => {
  //     opportunityRequest({variables: {name: name, email: email, phone: phone, description: desc }}).then((res) => {
  //       setSuccess(true)
  //       setTimeout(() => {
  //         document.getElementById('opportunity-dialog').style.display = 'none'
  //       }, 1500)
  //     })
  //   }

  //   const isDisabed = (name && name.length > 0) && (email && email.length > 0) && (phone && phone.length > 0)

  //   return <div id='opportunity-dialog' className='dialog'>
  //     <button onClick={() => document.getElementById('opportunity-dialog').style.display = 'none'}  style={{background: '#B92C3C', display: 'flex', top: '5px', right: '25px', position: 'fixed', alignItems: 'center', justifyContent: 'center', marginLeft: 'auto',borderRadius: '50px', width: '40px', height: '40px', padding: '12px', }}> X </button>

  //     {success &&
  //       <Grid className='content' style={{padding: '2rem 2rem', display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column', alignItems: 'center'}}>
  //         <h2> Thanks for your interest!</h2>
  //         <p> I will be in touch with you shortly. </p>
  //       </Grid>

  //     }
  //     {!success &&
  //       <Grid className='content' style={{padding: '2rem 2rem', display: 'flex', flexDirection: 'column',}}>
  //         <Grid item xs={12}>
  //           <h3 style={{fontSize: '12px', color: '#aaa', margin: '0px auto', textAlign: 'center'}}>INTERESTED IN JOINING OUR TEAM?</h3>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <h3 style={{fontSize: '20px', margin: '0px auto 1rem auto', textAlign: 'center'}}>What You Get</h3>
  //         </Grid>
  //         <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginRight: 'auto', }}>
  //           <Grid container style={{alignItems: 'center'}}>
  //           <List>
  //             {['Your own studio space, not just a chair.', 'Your own keys to the building, come and go as you please.', 'The opportunity to be featured on this website for free promotion of your services.'].map(e => {
  //               return <ListItem key={e} style={{margin: '.5rem 0'}}>
  //               <Typography component='p' style={{lineHeight: 1.5, borderLeft: '4px solid #B92C3C', paddingLeft: '.5rem', fontSize: '.9rem'}}>{e}</Typography>
  //             </ListItem>
  //             })}
  //           </List>
  //           </Grid>
  //         </Grid>
  //         <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginRight: 'auto', marginTop: '.5rem', width: '100%'}}>
  //           <Typography component='p' style={{lineHeight: 1.5, fontSize: '.9rem'}}>
  //             Please fill out the form below and we will reach out to you within a day or two if we have space available.
  //           </Typography>
  //         </Grid>
  //         <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginRight: 'auto', marginTop: '.5rem', width: '100%'}}>
  //           <Grid container style={{alignItems: 'center'}}>
  //             <Typography component='p' style={{fontSize: '14px', marginLeft: 0}}> Your name </Typography>
  //             <TextField name='name' variant='outlined' size='small' value={name} onChange={handleChange} style={{width: '100%', marginTop: '.25rem'}} type='text' />
  //           </Grid>
  //         </Grid>
  //         <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginRight: 'auto', marginTop: '.5rem', width: '100%'}}>
  //           <Grid container style={{alignItems: 'center'}}>
  //             <Typography component='p' style={{fontSize: '14px', marginLeft: 0}}> Your Email </Typography>
  //             <TextField name='email' variant='outlined' size='small' value={email} onChange={handleChange} style={{width: '100%', marginTop: '.25rem'}} type='text' />
  //           </Grid>
  //         </Grid>
  //         <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginRight: 'auto', marginTop: '.5rem', width: '100%'}}>
  //           <Grid container style={{alignItems: 'center'}}>
  //             <Typography component='p' style={{fontSize: '14px', marginLeft: 0}}> Phone </Typography>
  //             <TextField name='phone' variant='outlined' size='small' value={phone} onChange={handleChange} style={{width: '100%', marginTop: '.25rem'}} type='text' />
  //           </Grid>
  //         </Grid>
  //         <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginRight: 'auto', marginTop: '.5rem', width: '100%'}}>
  //           <Grid container style={{alignItems: 'center'}}>
  //             <Typography component='p' style={{fontSize: '14px', marginLeft: 0}}> Any additional information you want to include or ask </Typography>
  //             <TextField name='desc' variant='outlined' value={desc} onChange={handleChange} style={{width: '100%', marginTop: '.25rem'}} type='text' multiline />
  //           </Grid>
  //         </Grid>
  //         <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1.5rem'}}>
  //           <Grid container >
  //             <Button onClick={submitData}  disabled={!isDisabed} size='small' style={{margin: '0px auto', fontSize: '14px'}}>Submit</Button>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     }
  // </div>
  // }

  const addListener = () => {
    var iframe = document.createElement('iframe')
    iframe.style.display = 'block'
    iframe.src =
      'https://booksy.com/widget/index.html?country=us&lang=en&id=429672&uniqueId=429672-29&mode=inline&theme=lite-booksy-com'
    iframe.id = 'frame'
    document.getElementById('frameContainer').appendChild(iframe)
    const frame = document.getElementById('frame')

    window.addEventListener(
      'message',
      function (e) {
        e && e.data.events && (frame.style.height = e.data.events.resize.height + 'px')
      },
      false
    )

    iframe.onload = () => {
      document.getElementById('frameSkeleton').style.backgroundColor = 'rgba(0, 0, 0, 0)'
      document.getElementById('frameSkeleton').style.height = '0'
      setTimeout(() => {
        document.getElementById('frameSkeleton').style.display = 'none'
      }, 500)
    }
  }

  const getReviewData = () => {
    let request = {
      placeId: 'ChIJFdi3CN2nBYgRRwi8NNL7VRk',
      fields: ['reviews', 'rating', 'user_ratings_total'],
    }
    if (window.google) {
      const service = new window.google.maps.places.PlacesService(document.createElement('div'))
      service.getDetails(request, (a, b) => {
        setReviewData(a)
      })
    }
  }

  useEffect(() => {
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.addEventListener('load', function (event) {
      getReviewData()
    })
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBhr5Iq5YQOMMASCoZKCmcyHZ0QtLWE0OA&libraries=places'
    setTimeout(() => {
      document.getElementsByTagName('head')[0].appendChild(script)
    }, 100)

    ReactGA.initialize(process.env.REACT_APP_GA_ID)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  function openNav(e) {
    let topbar = document.getElementById('topbar')
    topbar.classList.toggle('open')
    topbar.classList.toggle('closed')
  }

  const {
    loading,
    error,
    data: textData,
  } = useQuery(TEXT, {
    variables: {
      id: '3eec884c-f022-4176-a3ab-b954f992171b',
    },
  })
  if (loading)
    return (
      <Skeleton
        style={{
          margin: '35vh auto',
          textAlign: 'center',
          backgroundColor: 'rgba(250, 250, 250, 0.25)',
        }}
        height={250}
        width={250}
        variant='circle'
      >
        {' '}
      </Skeleton>
    )
  if (error) return 'error'

  const fetchedData = textData?.document?.data || { texts: [] }
  const data = fetchedData

  return (
    <ThemeProvider theme={milanoTheme}>
      <CssBaseline />
      <Grid container className='mainwrapper'>
        <BugDialog />
        <RequestDialog />
        {/* <OpportunityDialog /> */}

        {LoginDialog()}
        <div id='book-dialog' className='book-dialog' style={{ zIndex: 99999 }}>
          <button onClick={() => (document.getElementById('book-dialog').style.display = 'none')}>
            <img alt='' src='./img/home/close-dark.svg' />
          </button>
          <a
            href='#'
            onClick={() => {
              document.getElementById('book-dialog').style.display = 'none'
              document.getElementById('bug-dialog').style.display = 'flex'
              document.getElementById('bug-dialog').style.opacity = '1'
              ReactGA.event({
                category: 'Click',
                action: 'Clicked report bug button',
              })
              submitUmamiEvent('clicked-report-bug', {})
            }}
          >
            Report a Bug
          </a>
          <div id='frameContainer'>
            <Skeleton id='frameSkeleton' style={{ WebkitTransition: 'all .15s linear' }} variant='rect' />
          </div>
        </div>

        <Grid item xs={12} id='topbar' className='closed topbar' style={{ WebkitTransition: '.15s linear' }}>
          <div id='topbar-items' className='topbar-items'>
            <img alt='' onClick={() => window.location.reload()} className='logo' src='./img/logo.png' />
            <img alt='' onClick={openNav} className='burger' src='../img/bars-solid.svg' />
          </div>
          <div id='dropdown' className='dropdown'>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  component='h4'
                  style={{
                    fontFamily: 'Montserrat',
                    textTransform: 'uppercase',
                  }}
                >
                  Hours
                </Typography>
                <Typography
                  style={{ fontFamily: 'Montserrat', fontSize: '13px' }}
                  component='p'
                  dangerouslySetInnerHTML={{
                    __html: data.texts.filter(text => text.position === 'hours')[0].content,
                  }}
                ></Typography>
              </Grid>
              <Grid item xs={12} style={{ padding: '1rem 0 0 0 ', margin: '1rem 0 0 0' }}>
                <Button
                  style={{ maxWidth: '90vw' }}
                  size='large'
                  onClick={() => {
                    document.getElementById('book-dialog').style.display = 'flex'
                    addListener()
                    ReactGA.event({
                      category: 'Click',
                      action: 'Clicked book button',
                    })
                    submitUmamiEvent('clicked-book-button', {})
                  }}
                >
                  Book Appointment
                </Button>
              </Grid>
              <Grid item xs={12} style={{ padding: '1rem 0 0 0 ', margin: '0 0 2rem 0' }}>
                <Button
                  style={{ maxWidth: '90vw' }}
                  size='large'
                  variant='outlined'
                  onClick={() => {
                    submitUmamiEvent('clicked-get-directions', {})

                    return (window.location =
                      'https://www.google.com/maps/dir/?api=1&destination=Milano%20Barber%20Shop&destination_place_id=ChIJFdi3CN2nBYgRRwi8NNL7VRk')
                  }}
                >
                  Get Directions{' '}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container id='contentWrapper'>
            <Hero data={data} />
            <Services data={data} />
            <Reviews reviewData={reviewData} />
            <LazyLoad height={1200} once offset={200} style={{ width: '100%' }}>
              <PhotoSection />
            </LazyLoad>
            {/* <Opportunity data={data} /> */}
          </Grid>
        </Grid>
        <LazyLoad height={1200} once style={{ width: '100%' }}>
          <Grid item xs={12} id='footer' className='footer'>
            <Grid container>
              <Grid item xs={12} className='header'>
                <img alt='' src='./img/m.png' />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} style={{ minHeight: '120px' }}>
                    <Typography component='h4'>Hours</Typography>
                    <Typography
                      component='p'
                      dangerouslySetInnerHTML={{
                        __html: data.texts.filter(text => text.position === 'hours')[0].content,
                      }}
                    ></Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component='h4'>Contact</Typography>
                    <Typography component='p'>
                      {' '}
                      {data.texts.filter(text => text.position === 'phone')[0].content} <br />
                      {data.texts.filter(text => text.position === 'address')[0].content}{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component='h4'>Follow Us</Typography>
                    <img
                      alt=''
                      style={{ cursor: 'pointer' }}
                      src='./img/home/facebook.svg'
                      onClick={() => {
                        window.location = 'https://www.facebook.com/MilanoBarbershopWaukesha/'
                        ReactGA.event({
                          category: 'Click',
                          action: 'Clicked facebook link',
                        })
                        submitUmamiEvent('clicked-facebook-link', {})
                      }}
                    />
                    <img
                      alt=''
                      style={{ cursor: 'pointer' }}
                      src='./img/home/instagram.svg'
                      onClick={() => {
                        window.location = 'https://www.instagram.com/milanobarbers/?hl=en'
                        ReactGA.event({
                          category: 'Click',
                          action: 'Clicked instagram link',
                        })
                        submitUmamiEvent('clicked-instagram-link', {})
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className='copyright'>
                <Typography component='p' style={{ marginTop: '75px' }}>
                  {' '}
                  © Milano Barbershop 2025
                </Typography>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {/* <Grid item xs={6}>
                   <a href='#'onClick={() => { document.getElementById('login-dialog').style.display = 'flex';document.getElementById('login-dialog').style.opacity = '1'; ReactGA.event({category: 'Click', action: 'Clicked admin button'}) }}>
                      Admin
                    </a>
                  </Grid> */}
                  <Grid item xs={12}>
                    <a
                      href='#'
                      onClick={() => {
                        document.getElementById('bug-dialog').style.display = 'flex'
                        document.getElementById('bug-dialog').style.opacity = '1'
                        ReactGA.event({
                          category: 'Click',
                          action: 'Clicked report bug button',
                        })
                        submitUmamiEvent('clicked-report-bug', {})
                      }}
                    >
                      Report a Bug
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className='promotion'
                onClick={() => {
                  document.getElementById('request-dialog').style.display = 'flex'
                  document.getElementById('request-dialog').style.opacity = '1'
                  ReactGA.event({
                    category: 'Click',
                    action: 'Clicked request button',
                  })
                  submitUmamiEvent('clicked-interested', {})
                }}
                style={{ WebkitTransition: 'all .25s linear' }}
              >
                Interested in your own custom website? Learn more.
                <img alt='' src='./img/arrow-right.png' />
              </Grid>
            </Grid>
          </Grid>
        </LazyLoad>
      </Grid>
    </ThemeProvider>
  )
}

export default PageContent
