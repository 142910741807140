import React, { useEffect } from "react";
import "./edit.css";
import { gql, useQuery } from "@apollo/client";
import {
  ThemeProvider,
  Typography,
  CssBaseline,
  Button,
  Grid,
} from "@material-ui/core/";
import { milanoTheme } from "./milano.theme";
import LazyLoad from "react-lazyload";
import { Bugs } from "./Bugs";
import { Opportunities } from "./Opportunities";

const REQUESTS = gql`
  query Request {
    requests {
      name
      email
      business
      description
      createdAt
    }
  }
`;

const Edit = () => {
  function openNav(e) {
    let topbar = document.getElementById("topbar");
    topbar.classList.toggle("open");
    topbar.classList.toggle("closed");
  }

  useEffect(() => {
    if (!localStorage.getItem("credentials")) {
      window.location = "/";
    }
  }, []);

  const { loading, error, data } = useQuery(REQUESTS);
  if (loading) return "loading";
  if (error) return "error";

  return (
    <ThemeProvider theme={milanoTheme}>
      <CssBaseline />
      <Grid container className="mainwrapper">
        <Grid
          item
          xs={12}
          id="topbar"
          className="closed topbar"
          style={{ webkitTransition: ".15s linear" }}
        >
          <div id="topbar-items" className="topbar-items">
            <img
              alt=""
              onClick={() => window.location.reload()}
              className="logo"
              src="./img/logo.png"
            />
            <img
              alt=""
              onClick={openNav}
              className="burger"
              src="../img/bars-solid.svg"
            />
          </div>
          <div id="dropdown" className="dropdown">
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: "8vh" }}>
                <Typography
                  component="h4"
                  style={{
                    fontFamily: "Montserrat",
                    textTransform: "uppercase",
                  }}
                >
                  Hours
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button style={{ maxWidth: "90vw" }} size="large">
                  Book Appointment
                </Button>
              </Grid>
              <Grid item xs={12} style={{ padding: "1rem 0 0 0" }}>
                <Button
                  style={{ maxWidth: "90vw" }}
                  size="large"
                  variant="outlined"
                  onClick={() =>
                    (window.location =
                      "https://g.page/milano-barber-shop-waukesha?share")
                  }
                >
                  Get Directions{" "}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container id="contentWrapper">
            <Grid item xs={12} className="section">
              <Grid container className="container">
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      className="slider-container"
                      id="review-slider-container"
                    >
                      {data &&
                        data.requests.map((request, index) => {
                          return (
                            <Grid
                              key={request.name}
                              container
                              style={{
                                paddingRight: "55px",
                                paddingLeft: "55px",
                                paddingBottom: "55px",
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                style={{ minWidth: "315px" }}
                                className="review-box"
                                style={{ height: "auto" }}
                                key={
                                  request.name
                                } /** onClick={ () => { document.getElementById('review-dialog').style.display = 'flex';console.log(index);document.getElementById('review-frame').scrollLeft = (window.innerWidth*(index-1))}} */
                              >
                                <Grid container className="review-head">
                                  <Grid
                                    item
                                    xs={6}
                                    className="star-container"
                                    style={{ textAlign: "center" }}
                                  >
                                    <Typography component="h5">
                                      {" "}
                                      {new Date(
                                        request.createdAt
                                      ).toString()}{" "}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography component="h5">
                                      {" "}
                                      {request.name}{" "}
                                    </Typography>
                                    <Typography component="h5">
                                      {" "}
                                      {request.email}{" "}
                                    </Typography>
                                    <Typography component="h5">
                                      {" "}
                                      {request.business}{" "}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid container className="review-body">
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ alignItems: "center" }}
                                  >
                                    <Typography component="p">
                                      {" "}
                                      {request.description}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Bugs />
        <Opportunities />
        <LazyLoad height={1200} once style={{ width: "100%" }}>
          <Grid item xs={12} id="footer" className="footer">
            <Grid container>
              <Grid item xs={12} className="header">
                <img alt="" src="./img/m.png" />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} style={{ minHeight: "120px" }}></Grid>
                  <Grid item xs={6}>
                    <Typography component="h4">Contact</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component="h4">Follow Us</Typography>
                    <img
                      alt=""
                      style={{ cursor: "pointer" }}
                      src="./img/home/facebook.svg"
                    />
                    <img
                      alt=""
                      style={{ cursor: "pointer" }}
                      src="./img/home/instagram.svg"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="copyright">
                <Typography component="p" style={{ marginTop: "75px" }}>
                  {" "}
                  © Milano Barbershop 2025
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <a>Admin</a>
                  </Grid>
                  <Grid item xs={6}>
                    <a>Report a Bug</a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className="promotion"
                style={{ webkitTransition: "all .25s linear" }}
              >
                Interested in your own custom website? Learn more.
                <img alt="" src="./img/arrow-right.png" />
              </Grid>
            </Grid>
          </Grid>
        </LazyLoad>
      </Grid>
    </ThemeProvider>
  );
};

export default Edit;
